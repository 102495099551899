<template>
  <div class="mt-2">

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100">
        <img
            fluid
            :src="require(isDark ? '@/assets/images/logo/promostore-white.svg' : '@/assets/images/logo/promostore.svg') "
            class="max-w-img-190 d-block m-auto"
            alt="Not authorized page"
        />
        <h1 class="mb-1 mt-3">
          Términos y condiciones
        </h1>

        <p><strong>&nbsp;</strong></p>
        <p>&Uacute;ltima actualizaci&oacute;n: 28 de febrero del 2022.</p>
        <p>&nbsp;</p>
        <p>Los presentes t&eacute;rminos y condiciones (en adelante, los <strong>&ldquo;T&eacute;rminos y Condiciones&rdquo;</strong>) constituyen el acuerdo de voluntades vinculante entre Productos y Servicios que Dignifican, S. de R.L. (referida para efectos de estos T&eacute;rminos y Condiciones como el <strong>&ldquo;Prestador de Servicios&rdquo;</strong>) y la persona f&iacute;sica que, de manera enunciativa mas no limitativa: <strong>(i) </strong>ingrese, visite, navegue y/o se registre en la P&aacute;gina Web del Prestador de Servicios, as&iacute; como en cualquiera de los otros medios respecto de los cuales sean aplicables estos T&eacute;rminos y Condiciones; y/o, <strong>(ii) </strong>solicite, contrate, compre y/o de cualquier otra forma adquiera los productos y/o servicios del Prestador de Servicios (en adelante, referido en lo individual como el <strong>&ldquo;Usuario&rdquo;</strong>, y en conjunto con el Prestador de Servicios, como las <strong>&ldquo;Partes&rdquo;</strong>).</p>
        <p>Considerando lo anterior, las Partes se obligan de conformidad con las siguientes cl&aacute;usulas:</p>
        <p>&nbsp;</p>
        <h3>PRIMERA. Aceptaci&oacute;n.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>Previo a la contrataci&oacute;n de cualquiera de los Servicios, el Usuario reconoce y acepta expresamente lo siguiente:</p>
        <ol>
          <li>El Usuario declara ser una persona f&iacute;sica mayor de edad, en pleno goce y ejercicio de sus facultades, con plena capacidad para obligarse de conformidad con los presentes T&eacute;rminos y Asimismo, el Usuario declara ser empleado de la Empresa, de manera que la prestaci&oacute;n de los Servicios se supeditar&aacute; en todo momento a que el Usuario contin&uacute;e trabajando para dicha Empresa.</li>
          <li>Al momento de ingresar, visitar, navegar y/o, en su caso, registrarse en la P&aacute;gina Web, as&iacute; como en cualquiera de los otros medios respecto de los cuales sean aplicables estos T&eacute;rminos y Condiciones, as&iacute; como al momento de contratar cualquiera de los Servicios del Prestador de Servicios, el Usuario acepta y reconoce que ha le&iacute;do y comprende en su totalidad el contenido de los presentes T&eacute;rminos y Condiciones y manifiesta su conformidad con su contenido. En caso de que el Usuario est&eacute; en desacuerdo con cualquiera de las disposiciones aqu&iacute; establecidas, deber&aacute; abstenerse de contratar cualquiera de los Servicios objeto de estos T&eacute;rminos y Condiciones.</li>
          <li>No ser&aacute; necesario contar con un contrato por escrito firmado para considerarse como v&aacute;lida la aceptaci&oacute;n de estos T&eacute;rminos y Condiciones por parte del Usuario, en tanto que dicha aceptaci&oacute;n ser&aacute; v&aacute;lida cuando se manifieste a trav&eacute;s de la P&aacute;gina Web u otros medios electr&oacute;nicos, tales como mensajes de correo electr&oacute;nico, a trav&eacute;s de aplicaciones de mensajer&iacute;a instant&aacute;nea, y/o a trav&eacute;s de todo tipo de Mensajes de Datos (tal y como se define en el C&oacute;digo de Comercio).</li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>El Usuario reconoce y acepta que har&aacute; uso de la P&aacute;gina Web &ldquo;as is&rdquo; (&ldquo;tal cual&rdquo;), en las condiciones, organizaci&oacute;n, versi&oacute;n, distribuci&oacute;n, y con las funcionalidades con las que el mismo se encuentre al momento de que el Usuario acceda a</li>
          <li>Con aprobaci&oacute;n previa de la Empresa, el Prestador de Servicios podr&aacute;, en cualquier momento, modificar y/o renovar los presentes T&eacute;rminos y Condiciones sin necesidad de previo aviso al Considerando lo anterior, el hecho de que, una vez que estos T&eacute;rminos y Condiciones sean modificados, el Usuario contin&uacute;e benefici&aacute;ndose de los Servicios, se tomar&aacute; como manifestaci&oacute;n de su consentimiento y aprobaci&oacute;n a dichas modificaciones y/o renovaciones que, en su caso, tengan lugar en el futuro. El Prestador de Servicios se compromete a, en la medida de lo posible, mantener actualizado en su P&aacute;gina Web la versi&oacute;n m&aacute;s reciente de los T&eacute;rminos y Condiciones. Sin perjuicio de lo anterior, el Prestador de Servicios podr&aacute; solicitar a trav&eacute;s de diversos medios que el Usuario manifieste su consentimiento respecto del contenido de estos T&eacute;rminos y Condiciones, tales como la aceptaci&oacute;n por escrito, o a trav&eacute;s de medios de verificaci&oacute;n electr&oacute;nica.</li>
        </ol>
        <h3>SEGUNDA. Definiciones.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>A reserva de que expresamente se se&ntilde;ale algo distinto, los t&eacute;rminos inicializados con may&uacute;scula, ya sean referidos en singular o plural, y sin importar el g&eacute;nero gramatical en que sean usados, ya sea masculino o femenino, tendr&aacute;n el significado que a los mismos se les atribuye en la presente Cl&aacute;usula, o el significado que a los mismos se les asigne en los presentes T&eacute;rminos y Condiciones, una vez que dichos t&eacute;rminos sean referidos entre comillas, entre los cuales se incluyen, de manera enunciativa mas no limitativa los que a continuaci&oacute;n se mencionan:</p>
        <p><strong>&ldquo;Compra&rdquo; o &ldquo;Transacci&oacute;n&rdquo;: </strong>cada una de las operaciones de adquisici&oacute;n por parte del Usuario de los Productos, la cual se entender&aacute; consumada, aceptada, perfeccionada y vinculante mediante la aceptaci&oacute;n del Usuario de los t&eacute;rminos de dicha operaci&oacute;n, ya sea, de manera enunciativa mas no limitativa: <strong>(i) </strong>al presionar el bot&oacute;n de &ldquo;Finalizar la Compra&rdquo;, &ldquo;Aceptar&rdquo;, &ldquo;Comprar&rdquo;, o cualquier otro similar a trav&eacute;s del cual el Usuario manifieste su consentimiento en la P&aacute;gina Web para realizar la Compra; <strong>(ii) </strong>mediante el consentimiento otorgado por el Usuario a realizar la Compra a trav&eacute;s de mecanismos de comunicaci&oacute;n electr&oacute;nica, tecnol&oacute;gicos, visuales, de comunicaci&oacute;n remota y/o de manera verbal, escrita, signos inequ&iacute;vocos o mediante la emisi&oacute;n de &oacute;rdenes de compra; <strong>(iii) </strong>mediante la aceptaci&oacute;n del Usuario a los t&eacute;rminos de la Compra a trav&eacute;s de correo electr&oacute;nico, v&iacute;a telef&oacute;nica y/o a trav&eacute;s de cualesquiera Mensajes de Datos (conforme se define dicho t&eacute;rmino en el C&oacute;digo de Comercio de M&eacute;xico), mensajes a trav&eacute;s de redes sociales del Prestador de Servicios.</p>
        <p><strong>&ldquo;Empresa&rdquo;: </strong>la persona moral Johnson Controls Enterprises M&eacute;xico, S. de R.L. de C.V., de la cual el Usuario es empleado.</p>
        <p>&nbsp;</p>
        <p><strong>&ldquo;Impuestos&rdquo;: </strong>significa cualesquiera contribuciones, impuestos o derechos (en cada caso, incurridos, contingentes o diferidos), ya sean federales, estatales o municipales, as&iacute; como actualizaciones, multas y recargos relacionadas con los conceptos antes referidos, a ser pagados en t&eacute;rminos de la Legislaci&oacute;n Aplicable; incluyendo de manera enunciativa mas no limitativa, el Impuesto Sobre la Renta, Impuesto al Valor Agregado, impuestos especiales sobre productos y servicios, aranceles, derechos de tr&aacute;mite aduanero, retenciones de impuestos, y otras contribuciones, cuotas o cargos de naturaleza impositiva o contributiva y los accesorios de cualquiera de los anteriores o sus equivalentes en t&eacute;rminos de la Legislaci&oacute;n Aplicable.</p>
        <p><strong>&ldquo;Legislaci&oacute;n Aplicable&rdquo;: </strong>las leyes, c&oacute;digos, reglamentos, disposiciones, y/o dem&aacute;s normatividad legal que se encuentre vigente y que sea aplicable a los Servicios objeto de estos T&eacute;rminos y Condiciones en territorio de los Estados Unidos Mexicanos.</p>
        <p><strong>&ldquo;Marca&rdquo;</strong>: cualquiera de las marcas registradas respecto de las cuales el Prestador de Servicios se encuentra debidamente autorizado por la Empresa para la comercializaci&oacute;n de los Productos de la Marca, siendo las siguientes: LTH; Am&eacute;rica Racing; Optima.</p>
        <p><strong>&ldquo;P&aacute;gina Web&rdquo;: </strong>el sitio de Internet principal del Prestador de Servicios, as&iacute; como cualquier otra p&aacute;gina que en un futuro lo sustituya o complemente, accesible a trav&eacute;s de la siguiente direcci&oacute;n: <a href="https://promostore.clarios.com/">https://promostore.clarios.com</a></p>
        <p><strong>&ldquo;Paqueter&iacute;a&rdquo;: </strong>los prestadores de servicios de mensajer&iacute;a y paqueter&iacute;a responsables de la entrega de los Productos adquiridos del Prestador de Servicios.</p>
        <p><strong>&ldquo;Pesos&rdquo; </strong>o <strong>&ldquo;Moneda Nacional&rdquo;: </strong>Pesos Mexicanos, moneda de curso legal en los Estados Unidos Mexicanos.</p>
        <p><strong>&ldquo;Productos&rdquo;: </strong>los bienes, productos y art&iacute;culos promocionales de la Marca, dise&ntilde;ados y comercializados por el Prestador de Servicios a trav&eacute;s de su P&aacute;gina Web, incluyendo todos sus modelos, formatos y/o presentaciones que se encuentren en un momento dado publicados en la P&aacute;gina Web.</p>
        <p><strong>&ldquo;Servicios&rdquo;: </strong>los servicios objeto de estos T&eacute;rminos y Condiciones, consistentes, de forma general, en la oferta, comercializaci&oacute;n, compraventa y env&iacute;o de los Productos.</p>
        <p><strong>&ldquo;Terceros&rdquo;: </strong>cualquier persona, sea f&iacute;sica o moral, nacional o extranjera, distinta del Prestador de Servicios y del Usuario, que se relacione, ya sea de manera directa o indirecta, con los Servicios objeto de estos T&eacute;rminos y Condiciones.</p>
        <h3>TERCERA. Objeto.</h3>
        <p>&nbsp;</p>
        <p>Los presentes T&eacute;rminos y Condiciones tienen por objeto regular los Servicios a prestar por el Prestador de Servicios a favor del Usuario, consistentes, de forma general, en la oferta, comercializaci&oacute;n, compraventa y env&iacute;o de los Productos.</p>
        <p>Salvo disposici&oacute;n en contrario, estos T&eacute;rminos y Condiciones son aplicables a toda la informaci&oacute;n, contenido, lineamientos, reglamentos, pol&iacute;ticas, productos y/o servicios en general que el Prestador de Servicios ofrezca y/o publique, de manera enunciativa mas no limitativa, a trav&eacute;s de: <strong>(i) </strong>su P&aacute;gina Web o cualquier p&aacute;gina de Internet que en un futuro la sustituya o complemente; y/o <strong>(ii) </strong>cualesquiera otros medios, sea cual sea su formato, en que se refiera y/o contenga un hiperv&iacute;nculo a los presentes T&eacute;rminos y Condiciones.</p>
        <h3>CUARTA. Vigencia.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>Los presentes T&eacute;rminos y Condiciones se encontrar&aacute;n vigentes en todo momento en que el Usuario acceda a la P&aacute;gina Web y/o haga uso de los Servicios del Prestador de Servicios, sin perjuicio del derecho del Prestador de Servicios de variarlos en cualquier momento.</p>
        <p>De conformidad con los presentes T&eacute;rminos y Condiciones, el Prestador de Servicios podr&aacute;, en cualquier momento y por cualquier motivo, sin responsabilidad alguna y sin necesidad de una resoluci&oacute;n judicial en tal sentido, y sin formalidad extrajudicial alguna, dar por terminado cualquier tipo de relaci&oacute;n que existiera con el Usuario y negarse a continuar prestando Servicio alguno, salvo por aquella Compra que haya estado en firme de conformidad con los t&eacute;rminos se&ntilde;alados en estos T&eacute;rminos y Condiciones. De igual manera, el Prestador de Servicios podr&aacute;, en cualquier momento, suspender, temporal o definitivamente, los Servicios prestados a favor del Usuario cuando este incumpla cualquiera de las disposiciones de los presentes T&eacute;rminos y Condiciones.</p>
        <h3>QUINTA. Servicios.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>Sin perjuicio de las dem&aacute;s disposiciones de los presentes T&eacute;rminos y Condiciones, los Servicios se regir&aacute;n conforme a lo siguiente:</p>
        <ol>
          <li><strong>Registro y Cuenta del Usuario: </strong>Para efecto de contratar los Servicios, el Usuario deber&aacute; registrarse en la P&aacute;gina Queda expresamente entendido que solo podr&aacute;n registrarse en la P&aacute;gina Web los Usuarios que sean empleados de la Empresa referida en estos T&eacute;rminos y Condiciones.</li>
        </ol>
        <p>Los Usuarios se registrar&aacute;n en la P&aacute;gina Web mediante invitaci&oacute;n individual que reciban por correo electr&oacute;nico a trav&eacute;s de los canales institucionales de comunicaci&oacute;n de la Empresa y de conformidad con el proceso de registro que el Prestador de Servicios disponga en la P&aacute;gina Web.</p>
        <p>&nbsp;</p>
        <p>Toda vez que el Usuario lleve a cabo el proceso de registro en la P&aacute;gina Web, se proveer&aacute; al Usuario una cuenta personal e intransferible (indistintamente, en adelante, la <strong>&ldquo;Cuenta&rdquo; </strong>o <strong>&ldquo;Perfil&rdquo; </strong>del Usuario) para hacer uso de los Servicios.</p>
        <p>Respecto de su Cuenta, el Usuario est&aacute; de acuerdo y se obliga a lo siguiente: <strong>(i) </strong>guardar su contrase&ntilde;a de acceso de manera segura y confidencial; <strong>(ii) </strong>no permitir que Terceros hagan uso de su Cuenta sin su autorizaci&oacute;n; <strong>(iii) </strong>negarse a usar la Cuenta de otro Usuario; <strong>(iv) </strong>negarse y abstenerse de vender, comprar, arrendar, intercambiar o transferir su Cuenta, o la Cuenta de otro Usuario, a cualquier Tercero; <strong>(v) </strong>negarse y abstenerse de cobrar a cualquier Tercero por acceder a la P&aacute;gina Web; y, <strong>(vi) </strong>acatar y cumplir con todas y cualesquiera indicaciones, t&eacute;rminos, condiciones, o pol&iacute;ticas de uso que el Prestador de Servicios determine, ya sea que se encuentren contenidas en los presentes T&eacute;rminos y Condiciones, en cualquier otro lineamiento, directriz, reglamento, pol&iacute;tica, o instrumento similar que el Prestador de Servicios emita o que le sea informado al Usuario.</p>
        <p>Cualquier Cuenta podr&aacute; ser cancelada de conformidad con el procedimiento de cancelaci&oacute;n de Cuenta que para tal efecto el Prestador de Servicios establezca. De igual forma, el Usuario entiende y acepta que todo uso que se haga de los Servicios mediante su&nbsp; Cuenta&nbsp; ser&aacute; de su entera y absoluta responsabilidad, sin perjuicio de las acciones de da&ntilde;os y perjuicios que el Prestador de Servicios pueda hacer valer.</p>
        <ol>
          <li><strong>Idoneidad de la Informaci&oacute;n: </strong>Toda la informaci&oacute;n que el Usuario proporcione al Prestador de Servicios deber&aacute; ser precisa, ver&iacute;dica, completa, e id&oacute;nea para los fines de los Servicios, as&iacute; como de los servicios prestados por la Paqueter&iacute;a. Entre la informaci&oacute;n que el Usuario proporcionar&aacute; al Prestador de Servicios, se incluye, enunciativamente, toda aquella relacionada con: el objeto del env&iacute;o a realizar, los datos de identificaci&oacute;n y/o de contacto del Usuario, los datos de identificaci&oacute;n y/o de contacto de la persona que el Usuario identifique como receptor de los Productos adquiridos (en adelante, el <strong>&ldquo;Destinatario&rdquo;</strong>, ya sea que el Destinatario sea el propio Usuario u otro Tercero; la informaci&oacute;n de la direcci&oacute;n y domicilio de entrega del env&iacute;o de los Productos.</li>
        </ol>
        <p>El Usuario reconoce expresamente que cualquier aspecto de la informaci&oacute;n que proporcione al Prestador de Servicios, que no cumpla con los requisitos establecidos en el presente inciso, podr&aacute; dar como resultado, de manera enunciativa m&aacute;s no limitativa: <strong>(i) </strong>la rescisi&oacute;n autom&aacute;tica de los Servicios;</p>
        <p><strong>(ii) </strong>la prestaci&oacute;n incorrecta de los servicios por parte de la Paqueter&iacute;a; <strong>(iii) </strong>da&ntilde;os, p&eacute;rdidas, y/o perjuicios ocasionados a los bienes entregados a la Paqueter&iacute;a, los cuales no ser&aacute;n reclamables; <strong>(iv) </strong>incurrir en costos y/o conceptos de contraprestaci&oacute;n adicionales a pagar, seg&uacute;n sea aplicable, al Prestador de Servicios o a la Paqueter&iacute;a.</p>
        <p>&nbsp;</p>
        <ol>
          <li><strong>Disponibilidad de los Productos: </strong>La venta de los Productos ofrecidos a trav&eacute;s de la P&aacute;gina Web estar&aacute; sujeta a la disponibilidad de dichos Productos, por lo que el hecho de que un Producto en particular aparezca exhibido en la P&aacute;gina Web no significa obligaci&oacute;n alguna a cargo del Prestador de Servicios de contar con unidades de dicho Producto en almac&eacute;n o en proceso de producci&oacute;n, haciendo su mejor y mayor esfuerzo para poder entregar dicho Producto en un tiempo razonable. Asimismo, el Prestador de Servicios se reserva en todo momento el derecho a variar la oferta de Productos exhibidos a trav&eacute;s de la P&aacute;gina Web, sin necesidad de aviso previo al Usuario.</li>
          <li><strong>Aplicabilidad de t&eacute;rminos de la Paqueter&iacute;a: </strong>El Usuario reconoce expresamente que, en caso de requerir el env&iacute;o de los Productos, ser&aacute;n aplicables en autom&aacute;tico los t&eacute;rminos y condiciones que cada Paqueter&iacute;a establezca para la prestaci&oacute;n de sus servicios; el Prestador de Servicios no cuenta con control, participaci&oacute;n, incidencia, o poder de decisi&oacute;n alguno, respecto de ninguno de los t&eacute;rminos y condiciones que las Paqueter&iacute;as establecen para la prestaci&oacute;n de sus</li>
        </ol>
        <p>Todas las condiciones, restricciones, caracter&iacute;sticas, prohibiciones, y/o limitantes aplicables a los env&iacute;os de los Productos ser&aacute;n en todo momento determinados por la Paqueter&iacute;a; entre otras, las condiciones que ser&aacute;n establecidas a discreci&oacute;n de la Paqueter&iacute;a, de manera enunciativa m&aacute;s no limitativa, se encuentran las siguientes: <strong>(i) </strong>dimensiones m&aacute;ximas; <strong>(ii) </strong>peso m&aacute;ximo; <strong>(iii) </strong>cargos por sobrepeso, as&iacute; como cualquier otro cargo o monto a pagar a la Paqueter&iacute;a, los cuales correr&aacute;n a cargo del Usuario y formar&aacute;n parte de la Contraprestaci&oacute;n pagadera al Prestador de Servicios; <strong>(iv) </strong>bienes cuyo env&iacute;o se proh&iacute;be; <strong>(v) </strong>tiempos de entrega, y/o, en caso de ser aplicable, de recolecci&oacute;n, de la mercanc&iacute;a y/o bienes objeto del env&iacute;o de los Productos; y, <strong>(v) </strong>las dem&aacute;s que la Paqueter&iacute;a de que se trate establezca en los t&eacute;rminos y condiciones aplicables a sus servicios.</p>
        <ol>
          <li><strong>Reclamos y seguros: </strong>el Prestador de Servicios no otorga por s&iacute; ninguna garant&iacute;a legal, fianza, y/o seguro respecto de los Servicios que ofrece. Sin perjuicio de lo anterior, es posible, siempre que sea aplicable conforme a los t&eacute;rminos y condiciones de la Paqueter&iacute;a, que ciertos seguros sean aplicables respecto del env&iacute;o de los Productos. Toda reclamaci&oacute;n o procedimiento de queja con la Paqueter&iacute;a relacionada con el env&iacute;o de los Productos ser&aacute; asistida y coordinada por el Prestador de Servicios, debiendo el Usuario coadyuvar con el Prestador de Servicios para tal efecto. No obstante lo anterior, el Prestador de Servicios no ser&aacute; responsable del fallo o decisi&oacute;n de la Paqueter&iacute;a en relaci&oacute;n con la reclamaci&oacute;n o procedimiento de queja de que se trate, salvo en casos imputables al Prestador de Servicios en los que medie dolo o negligencia grave.</li>
          <li><strong>Pol&iacute;tica de Reembolsos o Reposiciones: </strong>Las Partes acuerdan expresamente que no ser&aacute; procedente reembolso o reposici&oacute;n alguna salvo expresamente en casos de: <strong>- </strong>Defectos de fabricaci&oacute;n atribuibles al Prestador de Servicios que afecten de manera sustancial o total la naturaleza</li>
        </ol>
        <p>&nbsp;</p>
        <p>o funcionalidad de los Productos o cuando el Producto recibido no sea el correspondiente a la Compra efectuada por el Usuario por causa de error atribuible al Prestador de Servicios (en adelante, los <strong>&ldquo;Defectos de Fabricaci&oacute;n&rdquo;</strong>); o, <strong>2.- </strong>en caso de que la Paqueter&iacute;a extrav&iacute;e, da&ntilde;e el Producto de manera que se afecte sustancial o total la naturaleza o funcionalidad del Producto o de cualquier otra forma no entregue el Producto (en adelante, cualquiera de los anteriores ser&aacute; referido como una <strong>&ldquo;Contingencia&rdquo;</strong>), salvo que la Paqueter&iacute;a responda por el reembolso del valor pagado por el Usuario por el Producto afectado por la Contingencia o cuando la Contingencia se derive de causa atribuible al Usuario o por caso fortuito o de fuerza mayor conforme a la Legislaci&oacute;n Aplicable.</p>
        <p>Para efectos de claridad, queda expresamente entendido que no se considerar&aacute;n Defectos de Fabricaci&oacute;n, de manera enunciativa mas no limitativa: <strong>(i) </strong>discrepancias menores entre la muestra del Producto publicada en la P&aacute;gina Web y/o cualquier otro medio en que se oferten los Productos; <strong>(ii) </strong>defectos menores de impresi&oacute;n o manufactura del Producto que no afecte sustancial y totalmente la naturaleza o funcionalidad del Producto; <strong>(iii) </strong>los desgastes, da&ntilde;os y/o afectaciones menores que sufra el Producto durante el proceso de env&iacute;o; <strong>(iv) </strong>cualquier tipo de desgaste, da&ntilde;o, afectaci&oacute;n y/o defecto que se derive del uso del Producto por parte del Usuario en condiciones normales y acorde a su naturaleza; y, <strong>(iv) </strong>cuando las caracter&iacute;sticas, calidad, materiales o funcionalidades que el Usuario refiera como causa para solicitar el reembolso o reposici&oacute;n del Producto no est&eacute;n contempladas o sean distintas a las publicadas en la P&aacute;gina Web en la ficha t&eacute;cnica del Producto del que se trate.</p>
        <p>En caso de recibir un Producto con Defectos de Fabricaci&oacute;n, el Usuario deber&aacute; notificarlo inmediatamente al Prestador de Servicios dentro de las 72 (Setenta y Dos) horas siguientes a que el Usuario reciba el Producto, de lo contrario ning&uacute;n reembolso o reposici&oacute;n ser&aacute; v&aacute;lido. En su notificaci&oacute;n de Defectos de Fabricaci&oacute;n, el Usuario deber&aacute; adjuntar, cuando menos: <strong>(1) </strong>evidencia fotogr&aacute;fica del Producto que presente Defectos de Fabricaci&oacute;n; <strong>(2) </strong>informaci&oacute;n detallada de identificaci&oacute;n de la Compra del Producto; y, <strong>(3) </strong>la dem&aacute;s informaci&oacute;n o documentaci&oacute;n que en su momento el Prestador de Servicios le solicite para efecto de acreditar, identificar la Compra y/o el estado en que el Producto fue recibido.</p>
        <p>Las notificaciones relacionadas con Defectos de Fabricaci&oacute;n de los Productos deber&aacute;n enviarse a la direcci&oacute;n de correo electr&oacute;nico que a continuaci&oacute;n se se&ntilde;ala para tal efecto: <a href="mailto:ayuda@promostore.com"><strong><u>ayuda@promostore.com</u></strong></a></p>
        <p>Habiendo notificado el Usuario al Prestador de Servicios conforme al presente inciso, el Usuario podr&aacute; optar entre el reembolso de la Contraprestaci&oacute;n pagada por su Compra o la reposici&oacute;n del Producto por uno igual al adquirido. El Usuario reconoce y acepta expresamente que en caso de elegir el reembolso, la cantidad a devolver s&iacute; incluir&aacute; los Costos de Env&iacute;o as&iacute; como cualesquiera otros cargos</p>
        <p>&nbsp;</p>
        <p>o comisiones de Terceros que hubieren sido aplicables a la Compra de que se trate. Conforme a lo anterior, en caso de que el Usuario opte por el reembolso de la Contraprestaci&oacute;n, la cantidad a reembolsar ser&aacute; pagada al Usuario a trav&eacute;s del mismo mecanismo o m&eacute;todo de pago que se haya utilizado para la Compra.</p>
        <p>Se except&uacute;an de lo anterior las Compras en que se se&ntilde;ale como m&eacute;todo de pago el Descuento V&iacute;a N&oacute;mina, en cuyo caso la cantidad a reembolsar ser&aacute; acreditada como saldo a favor en la Cuenta del Usuario.</p>
        <p>El Prestador de Servicios contar&aacute; con un plazo razonable para acreditar el reembolso de que se trate, el cual ser&aacute; en todo caso de, cuando menos, 5 (Cinco) d&iacute;as h&aacute;biles, sujeto a los dem&aacute;s plazos atribuibles a procesadores de pago externos.</p>
        <h3>SEXTA. Contraprestaci&oacute;n.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>El Usuario pagar&aacute; al Prestador de Servicios una remuneraci&oacute;n econ&oacute;mica por la prestaci&oacute;n de los Servicios (la <strong>&ldquo;Contraprestaci&oacute;n&rdquo;</strong>). Son aplicables a cualquier Contraprestaci&oacute;n que el Usuario deba pagar al Prestador de Servicios las siguientes disposiciones:</p>
        <p>El Usuario pagar&aacute; al Prestador de Servicios por los Productos y Servicios una contraprestaci&oacute;n econ&oacute;mica, la cual se integrar&aacute; de conformidad con los precios, tarifas, modalidades, Costos de Env&iacute;o, montos, cantidades, Impuestos, comisiones o cargos de Terceros en relaci&oacute;n con el procesamiento de pagos y/o, en su caso, conceptos adicionales y/o promociones que sean aplicables conforme a la Legislaci&oacute;n Aplicable y/o que el Prestador de Servicios publique en la P&aacute;gina Web o indique al Usuario al momento de realizar la Compra (la <strong>&ldquo;Contraprestaci&oacute;n&rdquo;</strong>). No obstante lo anterior, el Prestador de Servicios se reserva en todo momento el derecho para, a su entera discreci&oacute;n y sin necesidad de previa notificaci&oacute;n, variar los precios, tarifas, modalidades, montos, cantidades, en su caso, conceptos adicionales y/o promociones aplicables de los Productos y Servicios.</p>
        <p>La Contraprestaci&oacute;n a pagar por los Servicios del Prestador de Servicios se regir&aacute; de la siguiente manera:</p>
        <ol>
          <li><strong>Monto a Pagar: </strong>La Contraprestaci&oacute;n a pagar se determinar&aacute; previo a la Compra, incluyendo en todo caso las cantidades que corresponda adicionar en relaci&oacute;n con el Impuesto al Valor Agregado (<strong>&ldquo;IVA&rdquo;</strong>), as&iacute; como con cualquier otro impuesto o gravamen que resulte aplicable conforme a la legislaci&oacute;n tributaria vigente en los Estados Unidos Mexicanos, presente o futura. Una vez aceptada la Compra, el Usuario quedar&aacute; obligado a pagar la Contraprestaci&oacute;n correspondiente.</li>
          <li><strong>Costos de Env&iacute;o: </strong>Salvo que expresamente se indique lo contrario en la P&aacute;gina Web previo a que el Usuario realice la Compra, todos los gastos de env&iacute;o, incluyendo, enunciativa mas no</li>
        </ol>
        <p>&nbsp;</p>
        <p>limitativamente, cualesquiera tarifas, Impuestos, derechos montos, cargos, y/o dem&aacute;s conceptos que deban pagarse a la Paqueter&iacute;a (en adelante, conjuntamente referidos como los <strong>&ldquo;Costos de Env&iacute;o&rdquo;</strong>) en relaci&oacute;n con el env&iacute;o de los Productos al Usuario y/o el Tercero que el Usuario designe, correr&aacute;n a cargo del Usuario, por lo que dichos Costos de Env&iacute;o se a&ntilde;adir&aacute;n a la Contraprestaci&oacute;n a pagar, formando parte integrante de la misma al momento de que el Usuario realice la Compra.</p>
        <ol>
          <li><strong>Forma de Pago: </strong>Sujeto a las disposiciones que el Prestador de Servicios determine para tal efecto, el Usuario pagar&aacute; al Prestador de Servicios la Contraprestaci&oacute;n que corresponda a trav&eacute;s del mecanismo de pago que las Partes acuerden previo al momento de la Compra.</li>
        </ol>
        <p>De manera enunciativa mas no limitativa y sujeto en todo momento a que el mecanismo de pago en cuesti&oacute;n se encuentre habilitado en la P&aacute;gina Web al momento de la Compra, el Usuario podr&aacute; a su elecci&oacute;n pagar por los Servicios: <strong>(i) </strong>mediante cargo a tarjeta de d&eacute;bito o tarjeta de cr&eacute;dito; <strong>(ii) </strong>mediante transferencia electr&oacute;nica o dep&oacute;sito a la cuenta bancaria del Prestador de Servicios; o, <strong>(iii) </strong>a trav&eacute;s del proceso administrativo gestionado por la Empresa consistente en que el monto de la Contraprestaci&oacute;n ser&aacute; descontado del salario del Usuario en su car&aacute;cter de empleado de la Empresa (en adelante, el <strong>&ldquo;Descuento V&iacute;a N&oacute;mina&rdquo;</strong>).</p>
        <p>En relaci&oacute;n con el Descuento V&iacute;a N&oacute;mina, las Partes acuerdan expresamente lo siguiente: <strong>(1) </strong>que dicho mecanismo de pago se regir&aacute; de conformidad con los t&eacute;rminos que para tal efecto establezca la Empresa y, en su caso, el acuerdo celebrado entre el Prestador de Servicios y la Empresa; <strong>(2) </strong>que esta modalidad de pago estar&aacute; limitada por el l&iacute;mite de cr&eacute;dito (en adelante, el <strong>&ldquo;L&iacute;mite de Cr&eacute;dito&rdquo;</strong>) que la Empresa determine unilateralmente para cada Usuario; <strong>(3) </strong>que el Usuario acepta y reconoce expresamente que, en caso de elegir el Descuento V&iacute;a N&oacute;mina como su forma de pago, autom&aacute;ticamente autoriza que la Empresa pague al Prestador de Servicios el monto total de la Contraprestaci&oacute;n a pagar por la Compra efectuada con cargo al salario que el Usuario percibe habitualmente en su car&aacute;cter de empleado de la Empresa; <strong>(4) </strong>que para el caso de que la Contraprestaci&oacute;n exceda el L&iacute;mite de Cr&eacute;dito del Usuario, dicha modalidad de pago se desactivar&aacute; autom&aacute;ticamente, de manera que el Usuario deber&aacute;, en su caso, elegir una forma de pago distinta.</p>
        <p>Los pagos a realizar por el Usuario podr&aacute;n ser procesados a trav&eacute;s de un proveedor externo de servicios de pago, elegido a discreci&oacute;n del Prestador de Servicios, por lo que cualquier tipo de cobro que se haga a trav&eacute;s de dichos Terceros incluir&aacute; de forma &iacute;ntegra todas las comisiones y dem&aacute;s conceptos aplicables conforme a lo establecido por el procesador de pagos.</p>
        <ol>
          <li><strong>Divisa: </strong>Toda Contraprestaci&oacute;n ser&aacute; exigible y pagada en Moneda Nacional. Todos los pagos efectuados por el Usuario deber&aacute;n hacerse en su totalidad, sin retenci&oacute;n alguna, bajo ning&uacute;n concepto, salvo aquellos que, en su caso, sean exigibles de conformidad con la Legislaci&oacute;n Cualquier</li>
        </ol>
        <p>&nbsp;</p>
        <p>pago que, de com&uacute;n acuerdo entre las Partes, haga el Usuario al Prestador de Servicios en divisa distinta a Moneda Nacional, se solventar&aacute; conforme al tipo de cambio para solventar obligaciones denominadas en moneda extranjera pagaderas en territorio nacional, que el Banco de M&eacute;xico publique en el Diario Oficial de la Federaci&oacute;n, en la fecha en que se realice dicho pago.</p>
        <ol>
          <li><strong>Lugar de Pago: </strong>Para los efectos que correspondan, toda Contraprestaci&oacute;n ser&aacute; exigible y pagadera en el domicilio del Prestador de Servicios, mismo que se establece en estos T&eacute;rminos y Condiciones en el apartado relativo a &ldquo;Notificaciones&rdquo;.</li>
          <li><strong>Plazo de pago: </strong>A excepci&oacute;n del caso de Descuento V&iacute;a N&oacute;mina, toda Contraprestaci&oacute;n ser&aacute; pagada por el Usuario al momento de realizar su Ning&uacute;n Producto ser&aacute; enviado o entregado hasta en tanto el Usuario no haya liquidado en su totalidad el pago de la Contraprestaci&oacute;n correspondiente. Sin perjuicio de lo anterior, en caso de que el Prestador de Servicios preste al Usuario cualquier Servicio, previo a que el pago correspondiente por parte del Usuario sea efectivamente liberado a favor del Prestador de Servicios, se considerar&aacute; que el plazo de pago para dicho pago ser&aacute; de m&aacute;ximo 3 (Tres) d&iacute;as naturales, contados a partir de la contrataci&oacute;n del Servicio, sin derecho a pr&oacute;rroga, en cuyo caso no se realizar&aacute; el embarque del Producto y se cancelar&aacute; la orden sin penalidad alguna. El plazo de pago referido en el presente inciso igualmente ser&aacute; aplicable para el caso de que cualquier pago del Usuario sea rechazado o, por cualquier otra raz&oacute;n imputable al Usuario, no pudiera ser liberado a favor del Prestador de Servicios.</li>
        </ol>
        <p>Trat&aacute;ndose del caso de Descuento V&iacute;a N&oacute;mina, el plazo de pago ser&aacute; el acordado por el Prestador de Servicios y la Empresa. No obstante lo anterior, en caso de que la Empresa, por cualquier motivo se negara a responder por las obligaciones de pago del Usuario a trav&eacute;s del Descuento V&iacute;a N&oacute;mina, el Usuario acepta expresamente que deber&aacute; pagar la Contraprestaci&oacute;n correspondiente a la Compra efectuada, mediante transferencia electr&oacute;nica o dep&oacute;sito a la cuenta bancaria que en su momento el Prestador de Servicios le indique, siendo aplicable en autom&aacute;tico el plazo de pago establecido previamente en este inciso.</p>
        <ol>
          <li><strong>Facturaci&oacute;n: </strong>A solicitud del Usuario, el Prestador de Servicios emitir&aacute; las facturas correspondientes respecto de los conceptos de Contraprestaci&oacute;n aplicables, dichas facturas contendr&aacute;n el desglose del Impuesto al Valor Agregado (IVA) correspondiente y contendr&aacute;n los requisitos legales y fiscales vigentes, presentes y/o futuros, conforme a las disposiciones legales aplicables en materia fiscal en los Estados Unidos Mexicanos.</li>
          <li><strong>Impago: </strong>En caso de incumplimiento de pago de cualesquiera conceptos de Contraprestaci&oacute;n, el Prestador de Servicios podr&aacute; suspender autom&aacute;ticamente los Servicios hasta en tanto el Usuario liquide el saldo pendiente de la Contraprestaci&oacute;n La Contraprestaci&oacute;n aplicable al Servicio</li>
        </ol>
        <p>&nbsp;</p>
        <p>contratado ser&aacute; pagada independientemente de: <strong>(i) </strong>el incumplimiento en que hubiera incurrido la Paqueter&iacute;a, as&iacute; como cualquier otro Tercero relacionado con los Servicios; <strong>(ii) </strong>la interrupci&oacute;n temporal del Servicio y/o la P&aacute;gina Web; o, <strong>(iii) </strong>la suspensi&oacute;n temporal o definitiva del acceso de la Cuenta del Usuario por la violaci&oacute;n por parte del Usuario a los presentes T&eacute;rminos y Condiciones.</p>
        <ol>
          <li><strong>Rescisi&oacute;n por Impago: </strong>En el caso de rescisi&oacute;n de la prestaci&oacute;n de los Servicios por falta de pago, el Usuario deber&aacute; liquidar al Prestador de Servicios todas y cada una de las cantidades debidas al momento de la rescisi&oacute;n, por concepto de Contraprestaci&oacute;n aplicable. Las cantidades referidas en el presente p&aacute;rrafo deber&aacute;n ser pagadas por el Usuario en un plazo no mayor a 5 (Cinco) d&iacute;as h&aacute;biles, contados a partir de la fecha de notificaci&oacute;n de la rescisi&oacute;n.</li>
          <li><strong>L) Cobro de Terceros: </strong>Los Servicios ser&aacute;n pagados exclusivamente al Prestador de Servicios, por lo que el Usuario deber&aacute; abstenerse de hacer pago alguno, espec&iacute;ficamente respecto de los Servicios, a favor de cualquier Los pagos que el Usuario decida realizar, en contravenci&oacute;n a lo anterior, ser&aacute;n de su entera responsabilidad, y de ninguna manera extinguir&aacute;n el derecho de cobro del Prestador de Servicios por los Servicios contratados por el Usuario. Sin perjuicio de lo anterior, el Prestador de Servicios se obliga a ceder a favor del Usuario todos los derechos que sean necesarios para reclamar cualquier cuesti&oacute;n relacionada con los servicios prestados por Terceros respecto de cualquiera de las Transacciones del Usuario en la P&aacute;gina Web; asimismo, el Prestador de Servicios coadyuvar&aacute; y acompa&ntilde;ar&aacute; al Usuario en el proceso de reclamaci&oacute;n que sea procedente.</li>
        </ol>
        <h3>S&Eacute;PTIMA. Uso y Acceso a la P&aacute;gina Web.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>El Usuario se obliga en todo momento a hacer uso de la P&aacute;gina Web y/o cualquiera otro medio de comunicaci&oacute;n del Prestador de Servicios conforme a lo establecido en los presentes T&eacute;rminos y Condiciones, &uacute;nicamente para fines l&iacute;citos y en estricto apego a la Legislaci&oacute;n Aplicable. Considerando lo anterior, el Usuario reconoce y acepta expresamente que ser&aacute; el &uacute;nico responsable del uso que haga de la P&aacute;gina Web, por lo que el Usuario, a partir de este momento, otorga al Prestador de Servicios la m&aacute;s amplia liberaci&oacute;n de responsabilidad que conforme a derecho proceda por el uso que haga de la P&aacute;gina Web. El Usuario acepta y reconoce que para hacer uso de la P&aacute;gina Web deber&aacute;, de manera enunciativa m&aacute;s no limitativa, bajo su exclusiva cuenta, cargo y responsabilidad:</p>
        <ol>
          <li>Contar con un equipo de c&oacute;mputo o dispositivo m&oacute;vil con acceso a Internet que le permita consultar p&aacute;ginas web a trav&eacute;s de un navegador compatible con los requisitos de la P&aacute;gina Web. El Usuario reconoce que es posible que se apliquen tarifas de datos de red de su m&oacute;vil y tarifas de mensajer&iacute;a, as&iacute; como otro tipo de tasas si accede o utiliza los servicios de un dispositivo inal&aacute;mbrico</li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>Llevar a cabo el mantenimiento preventivo y actualizaciones del hardware o dispositivos compatibles necesarios para acceder y utilizar la P&aacute;gina Web y los Servicios. El Prestador de Servicios no garantiza que los Servicios o cualquier parte de los mismos, funcionen en cualquier hardware o dispositivos en Adem&aacute;s, los Servicios pueden estar sujetos a aver&iacute;as y retrasos inherentes al uso de Internet y las comunicaciones electr&oacute;nicas.</li>
        </ol>
        <h3>OCTAVA. Seguridad de la Informaci&oacute;n.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>El Prestador de Servicios se compromete a implementar medidas de seguridad razonables conforme a la industria de compraventa de productos a trav&eacute;s de Internet, incluyendo, de manera enunciativa mas no limitativa, el uso de sistemas y protocolos de comunicaci&oacute;n segura y de resguardo seguro de la informaci&oacute;n del Usuario. No obstante lo anterior, el Usuario reconoce que, derivado de la naturaleza de los Servicios prestados, trat&aacute;ndose de Servicios prestados en l&iacute;nea, existen diversos riesgos tecnol&oacute;gicos, cibern&eacute;ticos, y de seguridad de la informaci&oacute;n, inherentes a las actividades realizadas en l&iacute;nea. Por tanto, el Usuario se obliga a tomar medidas de seguridad y de precauci&oacute;n adicionales a las ofrecidas por la P&aacute;gina Web. Considerando lo establecido en la presente cl&aacute;usula, el Usuario reconoce y acepta expresamente que al entregar cualquier informaci&oacute;n al Prestador de Servicios, asume el riesgo que dicha entrega representa, por lo que a partir de este momento libera al Prestador de Servicios de cualquier responsabilidad, de cualquier naturaleza, en relaci&oacute;n con la integridad y seguridad de la informaci&oacute;n y/o documentaci&oacute;n que decida voluntariamente entregar para efecto de la prestaci&oacute;n de los Servicios objeto de este instrumento.</p>
        <h3>NOVENA. Protecci&oacute;n de Datos Personales.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>En relaci&oacute;n con el objeto de estos T&eacute;rminos y Condiciones, el Prestador de Servicios actuar&aacute; con el car&aacute;cter de &ldquo;Responsable&rdquo;, conforme a lo dispuesto en la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares (en adelante, la <strong>&ldquo;Ley de Datos Personales&rdquo;</strong>), su Reglamento y dem&aacute;s Legislaci&oacute;n Aplicable en materia de protecci&oacute;n de datos personales. De conformidad con lo establecido en la Ley de Datos Personales, el Prestador de Servicios pone a disposici&oacute;n del Usuario su Aviso de Privacidad Integral (el <strong>&ldquo;Aviso de Privacidad&rdquo;</strong>), el cual podr&aacute; ser consultado en cualquier momento en la P&aacute;gina Web, a trav&eacute;s del siguiente enlace: <a href="https://promostore.clarios.com/">https://promostore.clarios.com</a></p>
        <p>Para efectos de la presente cl&aacute;usula se entender&aacute; por <strong>&ldquo;Datos Personales&rdquo; </strong>a cualquier informaci&oacute;n a trav&eacute;s de la cual se identifique al Usuario, incluyendo, de manera enunciativa mas no limitativa, a la informaci&oacute;n que se recaba del Usuario de acuerdo con lo establecido en el Aviso de Privacidad.</p>
        <p>&nbsp;</p>
        <p>El Usuario acepta y reconoce que, previo a la entrega de cualquier Dato Personal o contrataci&oacute;n de los Servicios, deber&aacute; haber le&iacute;do el Aviso de Privacidad del Prestador de Servicios. Asimismo, el Usuario acepta y reconoce que la contrataci&oacute;n de cualquiera de los Servicios se tomar&aacute; como manifestaci&oacute;n de su consentimiento al tratamiento de sus Datos Personales conforme a los t&eacute;rminos del Aviso de Privacidad.</p>
        <h3>D&Eacute;CIMA. Confidencialidad.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>Para efectos de la presente Cl&aacute;usula, se entender&aacute; como <strong>&ldquo;Emisor&rdquo; </strong>de la Informaci&oacute;n Confidencial (seg&uacute;n dicho t&eacute;rmino se define m&aacute;s adelante en la presente Cl&aacute;usula), a cualquiera de las Partes, seg&uacute;n corresponda, que emita, provea, entregue, revele, o env&iacute;e, Informaci&oacute;n Confidencial a la otra Parte; de igual forma, seg&uacute;n corresponda, se entender&aacute; como <strong>&ldquo;Receptor&rdquo; </strong>de la Informaci&oacute;n Confidencial, a cualquiera de las Partes que reciba, almacene, visualice, escuche, o a la que se le permita el acceso, a Informaci&oacute;n Confidencial de la otra Parte actuando como Emisor, conforme a lo aqu&iacute; establecido.</p>
        <p>Por <strong>&ldquo;Informaci&oacute;n Confidencial&rdquo; </strong>se entender&aacute; toda y cualquier informaci&oacute;n que sea revelada por cualquier medio por cualquiera de las Partes, incluyendo, de manera enunciativa m&aacute;s no limitativa, toda informaci&oacute;n y/o documentaci&oacute;n revelada en comunicaciones, sean por escrito o verbales, medios visuales, presentaciones, gr&aacute;ficas, informaci&oacute;n impresa, o cualquier informaci&oacute;n contenida en cualquier tipo de soporte o medio de almacenamiento, sea f&iacute;sico o digital, entre los cuales se mencionan, de manera enunciativa m&aacute;s no limitativa, mensajes de correo electr&oacute;nico, dispositivos de almacenamiento, sitios web, cloud computing, bases de datos, o cualquier otro medio de almacenamiento de datos; la informaci&oacute;n revelada por cualquiera de las Partes no necesitar&aacute; estar etiquetada como &ldquo;Confidencial&rdquo; para que el presente pacto de confidencialidad sea aplicable.</p>
        <p>Ambas Partes se obligan expresamente a mantener, resguardar y tratar, en estricta y absoluta confidencialidad y reserva toda la Informaci&oacute;n Confidencial que les sea proporcionada por parte del Emisor, o por parte de cualquier otra fuente ligada o relacionada con los Servicios. Dicho mantenimiento, resguardo, y tratamiento, de informaci&oacute;n deber&aacute; hacerse diligentemente bajo condiciones de confidencialidad y seguridad id&oacute;neas y suficientes para garantizar el cumplimiento de la presente Cl&aacute;usula, y en ning&uacute;n momento dichas condiciones de resguardo y confidencialidad respecto de la Informaci&oacute;n Confidencial recibida, podr&aacute;n ser menores o menos seguras que las utilizadas por cada una de las Partes para el resguardo y cuidado de su propia Informaci&oacute;n Confidencial.</p>
        <p>El Receptor podr&aacute; hacer uso de la Informaci&oacute;n Confidencial &uacute;nica y exclusivamente con el objeto de dar cumplimiento a sus obligaciones establecidas de conformidad con los presentes T&eacute;rminos y Condiciones. El Receptor no podr&aacute; revelar la Informaci&oacute;n Confidencial a Terceros no relacionados</p>
        <p>&nbsp;</p>
        <p>con los Servicios, y &uacute;nicamente podr&aacute; revelar informaci&oacute;n a sus empleados, empresas afiliadas, colaboradores, asociados, socios, o directores, en la medida de lo necesario para llevar a cabo el cumplimiento de sus obligaciones.</p>
        <p>Las Partes reconocen que, para efectos, la Informaci&oacute;n Confidencial es de la exclusiva propiedad del Emisor, y que su revelaci&oacute;n al Receptor no genera derecho alguno de propiedad intelectual, copropiedad, licencia, o cualquier otra forma de prerrogativa que ponga en duda la propiedad de la Informaci&oacute;n Confidencial, as&iacute; como su revelaci&oacute;n y/o recepci&oacute;n de ninguna manera ser&aacute; interpretada como una obligaci&oacute;n del Emisor de revelar toda o cualquier otra informaci&oacute;n espec&iacute;fica, ni generar&aacute; obligaciones distintas a las contra&iacute;das de conformidad con los presentes T&eacute;rminos y Condiciones.</p>
        <p>Las restricciones al uso o divulgaci&oacute;n de establecidas en la presente Cl&aacute;usula no ser&aacute;n aplicables a cualquier informaci&oacute;n que: <strong>(i) </strong>sea desarrollada independientemente por cualquiera de las Partes, sus subsidiarias o filiales, sin el uso o soporte de la Informaci&oacute;n Confidencial; <strong>(ii) </strong>se trate de informaci&oacute;n del dominio p&uacute;blico, sin que ninguna de las Partes haya violado este acuerdo; <strong>(iii) </strong>sean leg&iacute;timamente conocidos por el Receptor antes de su recepci&oacute;n por parte del Emisor; <strong>(iv) </strong>sean leg&iacute;timamente obtenidos por el Receptor de un Tercero sin incumplimiento por este &uacute;ltimo de ning&uacute;n contrato, convenio de confidencialidad, u obligaci&oacute;n de confianza; <strong>(v) </strong>sean comunicados por el Emisor con autorizaci&oacute;n expresa para su revelaci&oacute;n y/o divulgaci&oacute;n; y/o, <strong>(vi) </strong>sea requerida por orden de una autoridad jurisdiccional o autoridad competente, o en virtud de alguna disposici&oacute;n legal, en cuyo supuesto el Receptor deber&aacute; dar aviso inmediato al Emisor para efecto de que &eacute;ste tome las medidas que considere pertinentes, oblig&aacute;ndose a proporcionar &uacute;nicamente la Informaci&oacute;n Confidencial que le haya sido requerida.</p>
        <p>Concluido la relaci&oacute;n entre las Partes, el Emisor podr&aacute; solicitar al Receptor, en cualquier momento, la devoluci&oacute;n o destrucci&oacute;n de la Informaci&oacute;n Confidencial que el Receptor tenga o se encuentre en su poder y que sea propiedad del Emisor. El Receptor, en su caso, deber&aacute; devolver y/o, en su caso, destruir la Informaci&oacute;n Confidencial, seg&uacute;n lo solicite al Emisor, as&iacute; como confirmar por escrito al Emisor, dentro de un plazo razonable, de cuando menos 30 (Treinta) d&iacute;as naturales, contados a partir de la recepci&oacute;n de la solicitud correspondiente, de que la Informaci&oacute;n Confidencial ha sido devuelta al Emisor y/o, en su caso, destruida, seg&uacute;n lo solicite el Emisor.</p>
        <p>El incumplimiento de cualquiera de las Partes al pacto de confidencialidad establecido en la presente Cl&aacute;usula dar&aacute; lugar a que la Parte afectada reclame el pago de da&ntilde;os y perjuicios ocasionados por el incumplimiento del que se trate.</p>
        <h3>D&Eacute;CIMA PRIMERA. Propiedad Intelectual.</h3>
        <p>&nbsp;</p>
        <p>El Usuario, as&iacute; como las dem&aacute;s personas f&iacute;sicas o morales que guarden relaci&oacute;n con el Usuario, ya sea por s&iacute; o a trav&eacute;s de otras personas f&iacute;sicas o morales, incluyendo de forma enunciativa mas no limitativa, c&oacute;nyuges o concubinos, familiares, sean por afinidad o consanguinidad, en la l&iacute;nea ascendente, descendente, o colaterales, hasta el cuarto grado, as&iacute; como cualesquiera sociedades o personas morales de las cuales el Usuario o cualquiera de sus familiares antes mencionados formen parte, independientemente del car&aacute;cter que ostenten en ellas (en lo sucesivo, conjuntamente los anteriores, los <strong>&ldquo;Terceros Relacionados&rdquo; </strong>al Usuario, sin que la Empresa se considere Tercero Relacionado al Usuario), no podr&aacute;n por ning&uacute;n motivo y en ning&uacute;n caso, enunciativa mas no limitativamente: <strong>(i) </strong>revender, comercializar, distribuir, comerciar y de ninguna otra manera explotar comercialmente los Productos; <strong>(ii) </strong>hacer ning&uacute;n tipo de uso de la Marca y/o cualquier otra marca o signo distintivo propiedad del Prestador de Servicios, de la Empresa y/o del propietario de la Marca, seg&uacute;n sea el caso (sin importar si se encuentra registrada o no); y, <strong>(iii) </strong>copiar o intentar copiar, mejorar o intentar mejorar, ninguno de los Productos.</p>
        <p>Asimismo, el Usuario se obliga a no modificar y/o alterar ning&uacute;n componente t&eacute;cnico o tecnol&oacute;gico relacionado con los Servicios y/o la P&aacute;gina Web. De igual manera, ninguna de las disposiciones establecidas en estos T&eacute;rminos y Condiciones se entender&aacute; como una obligaci&oacute;n a cargo del Prestador de Servicios de permitir al Usuario acceder o modificar de forma alguna el c&oacute;digo fuente, o cualquier aspecto similar relacionado con la P&aacute;gina Web y/o los Servicios.</p>
        <p>El Usuario acepta y reconoce que el Prestador de Servicios se encuentra autorizado para utilizar la Marca y bajo dicho car&aacute;cter se encuentra debidamente autorizado por el leg&iacute;timo propietario de la Marca para el dise&ntilde;o, manufactura, comercializaci&oacute;n, venta y env&iacute;o de los Productos objeto de los Servicios. Asimismo, el Usuario acepta y reconoce que todos y cada uno de los elementos que integran o se relacionan con la Marca se encuentran protegidos por la Legislaci&oacute;n Aplicable en materia de propiedad intelectual, propiedad industrial, derechos de autor, derechos conexos y dem&aacute;s materias relacionadas.</p>
        <p>El Cliente acepta y reconoce que la Compra de cualquiera de los Productos no representa ning&uacute;n tipo de t&iacute;tulo, derecho, prerrogativa y/o licencia de ning&uacute;n tipo respecto de los Productos, la Marca y/o cualquier otra marca o signo distintivo propiedad del Prestador de Servicios, de la Empresa y/o del propietario de la Marca, seg&uacute;n sea el caso (sin importar si se encuentra registrada o no).</p>
        <p>De manera enunciativa mas no limitativa, se entender&aacute;n protegidos por lo establecido en esta cl&aacute;usula:</p>
        <p><strong>(1) </strong>la Marca de los Productos as&iacute; como las dem&aacute;s marcas y/o signos distintivos que sean propiedad del Prestador de Servicios, de la Empresa y/o del propietario de la Marca, seg&uacute;n sea el caso; <strong>(2) </strong>el dise&ntilde;o general de los Productos, incluyendo todas las im&aacute;genes, ilustraciones y dem&aacute;s elementos</p>
        <p>&nbsp;</p>
        <p>gr&aacute;ficos o visuales de los Productos; y, <strong>(3) </strong>todo el material de publicidad o mercadotecnia desarrollado por el Prestador de Servicios.</p>
        <p>Las disposiciones establecidas en la presente cl&aacute;usula subsistir&aacute;n y permanecer&aacute;n vigentes por tiempo indefinido a partir de la terminaci&oacute;n de la prestaci&oacute;n de los Servicios. En caso de violaci&oacute;n a lo manifestado en la presente cl&aacute;usula, el Usuario se obliga a pagar al Prestador de Servicios todo tipo de da&ntilde;os y perjuicios, incluyendo los de car&aacute;cter moral, que al efecto le ocasione.</p>
        <h3>D&Eacute;CIMA SEGUNDA. No Relaci&oacute;n Laboral.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>Las Partes reconocen y aceptan que la naturaleza de la relaci&oacute;n establecida entre las Partes en virtud de los Servicios es estricta y esencialmente mercantil, por lo que no existe relaci&oacute;n laboral ni de subordinaci&oacute;n entre ellas, o entre cualquiera de ellas y los empleados o el personal de la otra; por tanto, cada una de las Partes se obliga a defender, salvaguardar los intereses, y a sacar en paz y a salvo a la otra Parte, ante cualquier juicio o reclamaci&oacute;n que llegase a intentar en su contra cualquier persona que le preste servicios y/o que forme parte de su personal, sea propio o subcontratado. Ambas Partes se obligan a cumplir con todas las obligaciones que sean a su cargo derivadas de las relaciones laborales con su respectivo personal, sea propio o subcontratado, y ante terceros, incluyendo autoridades laborales, de seguridad social, administrativas y fiscales.</p>
        <h3>D&Eacute;CIMA TERCERA. Notificaciones.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>Las notificaciones y/o avisos que el Usuario haga al Prestador de Servicios deber&aacute;n efectuarse en d&iacute;as y horas h&aacute;biles, y se enviar&aacute;n por escrito, o cualquier otro medio que acuse o confirme su recibo por el destinatario, al domicilio se&ntilde;alado para tal efecto por el Prestador de Servicios, ubicado en Isabel La Cat&oacute;lica 462, Piso 1B, Fracc. Reforma, C.P. 91919, Veracruz, Veracruz, M&eacute;xico.</p>
        <p>Por su parte, las notificaciones y/o avisos que el Prestador de Servicios deba hacer al Usuario podr&aacute;n efectuarse, a discreci&oacute;n del Prestador de Servicios: <strong>(i) </strong>por escrito enviado a la direcci&oacute;n o domicilio que el Prestador de Servicios tenga registrada como domicilio del Usuario, incluso trat&aacute;ndose de su domicilio fiscal; <strong>(ii) </strong>por correo electr&oacute;nico enviado a cualquiera de las direcciones de correo electr&oacute;nico que el Usuario haya registrado en la P&aacute;gina Web del Prestador de Servicios, o desde la cual haya en cualquier momento contactado al Prestador de Servicios; o, <strong>(iii) </strong>mediante otros mecanismos electr&oacute;nicos de autenticaci&oacute;n o confirmaci&oacute;n que el Prestador de Servicios implemente a trav&eacute;s de la P&aacute;gina Web.</p>
        <p>Todas las notificaciones, avisos o comunicaciones que las Partes se dirijan en t&eacute;rminos de esta cl&aacute;usula se entender&aacute;n recibidas en la fecha de su entrega, siempre que se cuente con el acuse o</p>
        <p>&nbsp;</p>
        <p>confirmaci&oacute;n de recibo correspondiente, o bien, cuando en virtud de medios electr&oacute;nicos pueda verificarse que la notificaci&oacute;n correspondiente ha sido entregada o recibida por el Usuario.</p>
        <h3>D&Eacute;CIMA CUARTA. L&iacute;mite de Responsabilidad.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>Salvo por los casos expresamente contenidos en estos T&eacute;rminos y Condiciones, el Prestador de Servicios no ser&aacute; en ning&uacute;n momento responsable, bajo ning&uacute;n t&iacute;tulo, ante el Usuario, ni ante ning&uacute;n Tercero afectado, por los Servicios que el Usuario contrate del Prestador de Servicios, salvo en casos imputables al Prestador de Servicios en que medie dolo o negligencia grave. En ning&uacute;n caso el Prestador de Servicios ser&aacute; responsable de da&ntilde;o o perjuicio alguno, incluyendo, sin l&iacute;mite, lucro cesante, cualquier tipo de da&ntilde;os, p&eacute;rdidas, o gastos, sean estos directos, indirectos, inherentes o consecuenciales, costas, o cualquier otra responsabilidad de cualquier naturaleza, que surja o pudiera surgir derivado de la prestaci&oacute;n de los Servicios. De forma enunciativa mas no limitativa, el Prestador de Servicios no ser&aacute; responsable de forma alguna respecto de cualquiera de los siguientes supuestos:</p>
        <ol>
          <li>El uso que el Usuario haga de la P&aacute;gina Web y/o de los Servicios en general, incluyendo cualquier decisi&oacute;n, acto u omisi&oacute;n en que el Usuario incurra que se relacione, directa o indirectamente, con los Servicios, as&iacute; sean previos, simult&aacute;neos o posteriores a la contrataci&oacute;n de los</li>
          <li>La disponibilidad, continuidad de funcionamiento, imposibilidad de uso por razones t&eacute;cnicas, falla de rendimiento, error, omisi&oacute;n, interrupci&oacute;n, defecto, demora en la operaci&oacute;n, transmisi&oacute;n y/o falla de la P&aacute;gina</li>
          <li>El no cumplimiento de la expectativa de funcionamiento y/o utilidad que el Usuario le hubiere atribuido a la P&aacute;gina Web, los Productos y/o a los Servicios.</li>
          <li>Cualquier situaci&oacute;n, directa o indirectamente, relacionada con servicios prestados por Terceros distintos del Prestador de Servicios.</li>
          <li>Cualquier situaci&oacute;n relacionada, directa o indirectamente, con la calidad, modelos, medidas, colores, dise&ntilde;o global, materiales, funcionalidades espec&iacute;ficas y/o contenido en general de los Productos, salvo que se trate de Defectos de Fabricaci&oacute;n.</li>
          <li>Cualquier situaci&oacute;n, directa o indirectamente, relacionada con la rescisi&oacute;n de la relaci&oacute;n con el Usuario que se derive del incumplimiento de cualquiera de las disposiciones contenidas en los presentes T&eacute;rminos y Condiciones.</li>
          <li>Da&ntilde;os, perjuicios, p&eacute;rdidas y/o cualquier otra afectaci&oacute;n que el Usuario y/o cualquier Tercero sufra o resienta derivado de la prestaci&oacute;n de los Servicios.</li>
        </ol>
        <p>&nbsp;</p>
        <p>Lo previsto en la presente cl&aacute;usula continuar&aacute; vigente independientemente de la terminaci&oacute;n de la prestaci&oacute;n de los Servicios.</p>
        <h3>D&Eacute;CIMA QUINTA. Indemnizaci&oacute;n.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>Desde el momento en que el Usuario decide contratar los Servicios, en autom&aacute;tico otorga al Prestador de Servicios el m&aacute;s amplio deslinde de responsabilidad que en derecho proceda, por cualquier circunstancia o situaci&oacute;n relacionada con los Servicios prestados. De igual forma, el Usuario se compromete a defender, salvaguardar los intereses, a sacar en paz y a salvo, y a indemnizar al Prestador de Servicios, incluyendo conjunta e individualmente a sus funcionarios, directores, empleados, accionistas, socios, ejecutivos, comisionistas, y agentes, respecto de cualquier reclamo, demanda, o acci&oacute;n que cualquier Tercero llegara a entablar en su contra derivado de cualquier situaci&oacute;n directa o indirectamente relacionada con los Servicios prestados a favor del Usuario.</p>
        <h3>D&Eacute;CIMA SEXTA. Rescisi&oacute;n.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>De manera enunciativa, ser&aacute;n causas de rescisi&oacute;n de los Servicios las que a continuaci&oacute;n se mencionan, mediante aviso dirigido a la Parte que, en su caso, haya incurrido en la causal de rescisi&oacute;n aplicable, sin necesidad de resoluci&oacute;n judicial y sin responsabilidad alguna para la Parte que notifica la rescisi&oacute;n:</p>
        <ol>
          <li>En caso de que alguno de los empleados o personal de las Partes, ya sea propio y/o subcontratados, as&iacute; como cualquier otro dependiente o funcionario, demande laboralmente a la Parte contraria, y la Parte responsable no cumpliere con sus obligaciones de defensa establecidas en el presente</li>
          <li>Que cualquiera de las Partes proporcione informaci&oacute;n y/o documentaci&oacute;n</li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>Que en cualquier momento y/o cualquier raz&oacute;n el Usuario deje de ser empleado de la</li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>Cualquier otra expresamente establecida como causal de rescisi&oacute;n en el presente</li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>Que el Usuario incumpla con cualquiera de las disposiciones establecidas en los presentes T&eacute;rminos y Condiciones.</li>
        </ol>
        <h3>D&Eacute;CIMA S&Eacute;PTIMA. Acuerdo Total.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>Salvo que exista un acuerdo distinto vinculante entre las Partes, lo establecido en los presentes T&eacute;rminos y Condiciones representa su acuerdo &uacute;nico y total en relaci&oacute;n con los Servicios, por lo que el Usuario renuncia a cualquier declaraci&oacute;n y/o garant&iacute;a, sea expresa, t&aacute;cita, impl&iacute;cita, o inferida, que no se encuentre expresamente establecida en estos T&eacute;rminos y Condiciones, de manera que ser&aacute; nula</p>
        <p>&nbsp;</p>
        <p>de pleno derecho cualquier negociaci&oacute;n, acuerdo, promesa o entendido previo a la contrataci&oacute;n de los Servicios.</p>
        <h3>D&Eacute;CIMA OCTAVA. Autonom&iacute;a de Disposiciones.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>Las Partes convienen en que la nulidad, invalidez, ilegalidad o cualquier vicio en cualquiera de las disposiciones de estos T&eacute;rminos y Condiciones solo afectar&aacute;n a dicha disposici&oacute;n, por lo tanto, no afectar&aacute; a las dem&aacute;s disposiciones aqu&iacute; establecidas, las cuales, conservar&aacute;n su fuerza vinculante.</p>
        <h3>D&Eacute;CIMA NOVENA. Cesi&oacute;n.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>El Usuario no podr&aacute; ceder parcial o totalmente los derechos y obligaciones derivados del presente acuerdo. El Prestador de Servicios, por su parte, podr&aacute; con autorizaci&oacute;n de la Empresa ceder los derechos u obligaciones derivados de los presentes T&eacute;rminos y Condiciones, en cuyo caso bastar&aacute; que le notifique al Usuario sobre dicha cesi&oacute;n, se&ntilde;alando la informaci&oacute;n que identifique al cesionario.</p>
        <h3>VIG&Eacute;SIMA. Resoluci&oacute;n Amistosa de Controversias.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>Previo a cualquier acci&oacute;n o reclamaci&oacute;n legal del Usuario en contra del Prestador de Servicios, el Usuario deber&aacute;, en un plazo de, cuando menos, 30 (Treinta) d&iacute;as naturales, contados a partir de la fecha en que se haya suscitado la controversia de que se trate, permitir el establecimiento, y participar de buena fe, de negociaciones y comunicaci&oacute;n con el Prestador de Servicios tendientes a la resoluci&oacute;n amistosa de la controversia suscitada. En caso de que, derivado de las negociaciones referidas en el presente p&aacute;rrafo, las Partes lleguen a un acuerdo satisfactorio para ambos respecto de la resoluci&oacute;n de la controversia suscitada, el Usuario y el Prestador de Servicios deber&aacute;n, a trav&eacute;s de sus representantes legales, celebrar un convenio de finiquito y liberaci&oacute;n amplia de responsabilidad, el cual podr&aacute;, a coste de la Parte interesada, ser formalizado ante Fedatario P&uacute;blico, en cuyo caso, ambas Partes se comprometen a acudir a la firma de las escrituras correspondientes que se deban otorgar ante el Fedatario P&uacute;blico que, de com&uacute;n acuerdo entre las Partes, se elija.</p>
        <h3>VIG&Eacute;SIMA PRIMERA. Legislaci&oacute;n y Jurisdicci&oacute;n Aplicable.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>Las Partes se someten expresamente a la competencia de los tribunales competentes de la ciudad de Monterrey, Nuevo Le&oacute;n, as&iacute; como a la legislaci&oacute;n aplicable en los Estados Unidos Mexicanos, para el caso de cualquier controversia o interpretaci&oacute;n de los presentes T&eacute;rminos y Condiciones, renunciando expresamente a la jurisdicci&oacute;n o competencia que pudiera corresponderles en virtud de su domicilio, actual o futuro, o cualquier causa generadora de competencia.</p>

      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */

import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {

  methods: {
    loginRoute() {
      const user = JSON.parse(localStorage.getItem('userData'))
      return getHomeRouteForLoggedInUser(user ? user.role : null)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
.max-w-img-190{
  max-width: 190px;
}
</style>
